%baseline {
  color: $textColor;
  margin: 0;
  font-size: 1.4rem;
  font-family: $font-roboto;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: $mainBkg;
}

// Variables
// colors
$color-white: #fff;
$color-black: #000;
$color-system-green: #007d21;
$color-lighter-blue: #E8F9FA;
$color-light-blue: #F0F9FA;
$color-dark-river-blue: #1A3D4D;
$color-blue: #CCEDEE;
$color-darker-blue: #1A3D4D;
$color-phoenix-red: #db3725;
$color-phoenix-red-d20: #b21f13;
$color-phoenix-red-d40: #8a1500;
$color-phoenix-red-d60: #58160f;
$color-phoenix-red-d80: #2c0b07;
$color-phoenix-red-d90: #150504;
$color-phoenix-red-l20: #ea695b;
$color-phoenix-red-l40: #fc9489;
$color-phoenix-red-l60: #58160f;
$color-phoenix-red-l80: #2c0b07;
$color-phoenix-red-l90: #150504;
$color-almost-black: #001823;
$color-almost-black-d20: #000304;
$color-almost-black-l10: #1d333d;
$color-almost-black-l20: #3a4f59;
$color-almost-black-l30: #5e7079;
$color-gray: $color-almost-black-l30;
$color-almost-black-l40: #7b8a92;
$color-almost-black-l50: #97a3a9;
$color-almost-black-l60: #b2bbbf;
$color-almost-black-l70: #d2d7d9;
$color-almost-black-l80: #e3e6e7;
$color-lighter-gray: #D9D9D9;
$color-light-gray: $color-almost-black-l80;
$color-dark-gray: #ccc;
$color-almost-black-l90: #f2f3f3;
$color-sunrise-orange: #f67b37;
$color-sunrise-orange-d20: #d84c08;
$color-sunrise-orange-d40: #bc340e;
$color-sunrise-orange-l20: #f89a43;
$color-sunrise-orange-l40: #f6b563;
$color-cactus-green: #567248;
$color-cactus-green-d20: #1d3d26;
$color-cactus-green-d40: #182a10;
$color-cactus-green-l20: #5d8b67;
$color-cactus-green-l40: #93cba4;
$color-coral: #FFB5AD;

// main
$mainBkg: #FAFAFA;
$backgroundLightBlue: $color-light-blue;
$color-brand-red: #dd3826;
$color-brand-brown: #3c2320;
$primary2-color: #30718D;
$textColor: $color-almost-black;
$textHelpHintColor: $color-almost-black-l30;
$linkColor: $color-almost-black;
$linkUnderlineColor: $color-phoenix-red;
$darkBackground: $color-almost-black;
$separator: #e2e4e6;
$sectionSeparator: #e0e0e0;
$gridVerticalSectionSeparator: $color-almost-black-l70;

// Color theme
$primary-color: $color-phoenix-red;
$primary-hover-color: $color-phoenix-red-d40;
$primary2-hover-color: #114A61;
$primary-active-color: $color-phoenix-red-d60;
$primary2-active-color: #0d3b4e;
$secondary-color: #009CAD;
$tertiary-color: transparent;

$success-text-color: #5cb85c;
$error-text-color: $primary-color;
$error-smalltext-color: $color-phoenix-red-d20;
$warning-text-color: #8a6d3b;
$info-text-color: #31708f;

$success-backg-color: #007558;
$error-backg-color: $color-phoenix-red-d20;
$warning-backg-color: #E06C00;
$info-backg-color: #00638C;
$info_backg-color-static: #D6F3FF;

$success-light-backg-color: #CDF6EC;
$error-light-backg-color: #fbebea;
$warning-light-backg-color: #FFF1E4;
$info-light-backg-color: #d6f3ff;

$alert-info-txt-color: #004F70;
$tooltip-info-arrow-color: $primary2-color;
$tooltip-info-backg-color: $warning-light-backg-color;

$alert-warning-txt-color: #572A01;
$alert-warning-txt-hover-color: $color-phoenix-red-d20;
$tooltip-warning-arrow-color: $warning-backg-color;

$disabled-bg-color: $color-almost-black-l80;

// ADA colors
$ada-normal-size-gray-on-white: #757575;
$ada-normal-size-gray-on-e3e6e7: #666;
$ada-large-size-gray-on-white: #8C8C8C;
$ada-large-size-gray-on-e3e6e7: #696969;
$ada-light-gray: #e6e9ea;
$ada-large-size-gray-on-e6e9ea: #667982;

$mainFocusOutlineColor: $color-almost-black;
$footerFocusOutlineColor: $color-black;
$focusOnBlackBkgdColor: $color-white;
$focusTextColor: #900;
$adaGreyOnWhite: $color-gray;
$tableBorderColor: $color-almost-black-l70;

// Breakpoints
$max-sm: 639px !default;
$min-md: 640px !default;
$max-md: 991px !default;
$min-lg: 992px !default;
$max-lg: 1440px !default;
$min-xl: 1441px !default;
$grid-breakpoints: (
    sm: 0,
    md: 640px,
    lg: 992px,
    xl: 1441px,
);

// Header
$hamburger-position-top: 1.3rem;
$header-top-backg-color: $color-darker-blue;
$header-top-links-radius: 1.4rem;

// Menu
$mobile-menu-active-backg-color: $color-blue;
$mobile-menu-hover-backg-color: $color-light-blue;

// Typography
$font-size-base: 0.875rem; // Material-ui CssBaseline 14px
$font-roboto: 'Roboto', sans-serif;
$font-ibm-plex-serif: 'IBM Plex Serif', serif;
$font-weight-thin:       300;
$font-weight-normal:     400;
$font-weight-medium:     500;
$font-weight-md-bold:    600;
$font-weight-bold:       700;
$font-weight-black:      900;

// Buttons
$btn-border-width: 0.2rem;
$btn-border-style: solid;
$btn-border-radius: 3rem;
$btn-primary-bg: $primary-color;
// color 2 for button
$btn-primary2-bg: $primary2-color;
// Links
$link-primary-color: $primary-color;
$link-secondary-color: $primary2-color;
$link-on-light-bkgd-secondary-color: #25576D;
$link-primary-hover-color: $color-phoenix-red-d20;
$link-secondary-hover-color: $primary2-hover-color;
$link-primary-active-color: $color-phoenix-red-d40;
$link-secondary-active-color: $primary2-active-color;
$link-a: $primary2-color; //#3344dd; // Level AAA conformance
$link-a-link: $link-a;
$link-a-visited: #804180; //#804180; // Level AAA conformance
$link-a-active: #DB3725; //#b50010; // Level AAA conformance
$link-a-hover: $link-a-active;
$link-a-focus: $link-a-active;
$btn-disabled-bg-color: $color-almost-black-l70;
$btn-disabled-color: #5c5c5c;

// Form - Input, Textarea
$form-control-theme: $color-almost-black;
$input-label-color: $color-almost-black;
$input-focus-label-color: $color-almost-black-l30;
$input-error-border-color: $color-phoenix-red-d20;
$input-border-radius: 0.4rem;

// Dialog
$dialog-backdrop: rgba(0, 0, 0, 0.35);
$dialog-border-radius: 0.4rem;

// Spotlight
$spotlight-main: $primary-color;
$spotlight-alternate: #97C5CF;

// alert
$alert-error-txt-color: $color-phoenix-red-d40;
$alert-border-radius: 0.4rem;
$alert-header-on-bkgd-color: #25576D;

// Radio
$radio-default-backg-color: $color-white;
$radio-default-border-color: $color-almost-black-l30;
$radio-default-label-color: $color-almost-black;
$radio-default-icon-color: $color-almost-black-l30;
$radio-hover-backg-color: $color-white;
$radio-hover-border-color: $color-almost-black;
$radio-hover-label-color: $color-almost-black;
$radio-hover-icon-color: $color-almost-black;
$radio-checked-backg-color: $color-dark-river-blue;
$radio-checked-border-color: $color-dark-river-blue;
$radio-checked-label-color: $color-white;
$radio-checked-icon-color: $color-white;
$radio-error-checked-icon-color: $color-phoenix-red-d20;

// Checkbox
$checkbox-border-radius: 0.4rem;
$checkbox-default-backg-color: $color-white;
$checkbox-default-border-color: $color-almost-black-l30;
$checkbox-default-label-color: $color-almost-black;
$checkbox-default-icon-color: $color-almost-black-l30;
$checkbox-hover-backg-color: $color-white;
$checkbox-hover-border-color: $color-almost-black;
$checkbox-hover-label-color: $color-almost-black;
$checkbox-hover-icon-color: $color-almost-black;
$checkbox-checked-backg-color: $color-dark-river-blue;
$checkbox-checked-border-color: $color-dark-river-blue;
$checkbox-checked-label-color: $color-white;
$checkbox-checked-icon-color: $color-white;
$checkbox-error-checked-icon-color: $color-phoenix-red-d20;

// Tabs
$tabsBorderColor: $color-almost-black-l70;
$tabsBackgroundColor: $color-almost-black-l90;
$tabsTextColor: $color-almost-black;
$tabsIndicatorColor: $color-phoenix-red;
$tabsIndicatorColor2: $primary2-color;

// Badge
$badgeBackgroundColor: #d3d7d9;
$badgeColor: #061822;

// Eyebrow
$eyebrow-color: $primary2-color;

// Accordion
$accordion-border-radius: 0.4rem;
$accordion-bkgd-border-radius: 0.3rem;
$accordion-border-color: $primary2-color;
$accordion-tab-border-color: #9aa2a8;
// task accordion
$accordion-task-uncompleted: $color-almost-black-l60;
$accordion-task-completed: $success-backg-color;
$accordion-task-hd-open-color: $primary2-color;
$accordion-task-hd-close-color: $color-white;
$accordion-task-icon-bkgd-uncompleted-color: $color-almost-black-l90;
$accordion-task-icon-bkgd-completed-color: $success-light-backg-color;

// Icons
$icon-color: $primary2-color;

// Paper
$paperBorderColor: $color-almost-black-l70;
$paper-border-radius: 0.4rem;

// Card
$card-border-radius: 0.4rem;
$card-muted-border-color: $color-almost-black-l70;

// Section
$section-border-radius: 1.5rem;

// break
$separation-color: $color-coral;

// Blade
$bladeBkg: $mainBkg;
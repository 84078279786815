@import 'variables';
@import 'baseline';
@import 'mixins';

// HEADER
// -----------------------------
$header-height-lg: 8.2rem;
$header-height-sm: 7.2rem;

@media (max-width: $max-md) {
  .MuiButtonBase-root.header-profile {
    margin-right: 3rem;
  }
}
.menu-space {
  margin-bottom: 2rem;
}
.link-lists-container {
  .mainSection {
    padding-bottom: 1.4rem;
    .complete,
    .current {
      .sectionTitle {
        color: $color-phoenix-red;
      }
    }
  }
  .sectionList {
    padding-left: 0;
  }
  .sectionListItem {
    list-style-type: none;
    font-size: 1.7rem;
    font-weight: 500;
  }
  .sectionTitle {
    display: inline-block;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    color: $ada-normal-size-gray-on-white;
  }
  .sectionCompleteIcon {
    vertical-align: text-bottom;
    margin-left: 1rem;
  }
  a {
    color: $color-almost-black;
    &:hover {
      color: $color-phoenix-red;
    }
  }
  .link-list {
    &:first-child {
      margin-top: 1rem;
    }
    margin-top: 3.5rem;
  }
}
.icon-links-wrapper {
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  .menu-svg-icon {
    display: inline-block;
    min-width: 3.5rem;
    svg {
      fill: inherit;
      width: 2.6rem;
      height: 2.6rem;
    }
  }
}
.menu-list {
  a, button {
    font-weight: $font-weight-md-bold;
    .menu-text .MuiTypography-root {
      color: $color-almost-black;
      border-bottom: 1px solid transparent;
    }
    &:hover {
      .menu-svg-icon svg {
        fill: $color-phoenix-red;
      }
      .menu-text {
        .MuiTypography-root {
          color: $color-phoenix-red;
          border-bottom: 1px solid $color-phoenix-red;
        }
      }
    }
  }
  .menu-text-no-link .MuiTypography-root {
    color: $color-almost-black;
  }
  button {
    cursor: pointer;
  }
  .chat-univ button {
    border: 0;
    background: none;
    padding: 0;
    .MuiListItemIcon-root {
      padding-right: 0.4rem;
    }
  }
  .menu-text {
    display: inline-block;
    vertical-align: bottom;
    .MuiTypography-root {
      font-weight: inherit;
      color: $ada-normal-size-gray-on-white;
    }
    &.current {
      .MuiTypography-root {
        font-weight: $font-weight-md-bold;
        color: $color-phoenix-red;
      }
    }
  }
}

// CUSTOM
// -----------------------------
// form
.formControlWrapper {
  max-width: 50rem;
}
// Lead text
.leadText {
  font-size: 2rem;
  line-height: 1.6;
  font-weight: $font-weight-medium;
  margin-bottom: 3rem;
}
// List
ul.unordered-list {
  margin: 0;
  list-style-type: square;
  font-size: 2.2rem;
  padding-left: 3rem;
  li {
    padding: 0 0 1.5rem;
  }
  // default - basic list
  &.main {
    padding-left: 1.9rem;
    margin-bottom: 1.5rem;
    li {
      padding: 0 0 0.6rem;
      > * {
        font-size: 1.6rem;
        line-height: 1.5;
        @media (max-width: $max-sm) {
          font-size: 1.4rem;
          display: block;
        }
      }
    }
  }
  &.tight li {
    padding: 0;
  }
  &.nested-list {
    .unordered-list.main {
      margin-top: 1rem;
      margin-bottom: 0;
      padding-left: 1.5rem;
    }
  }
}
ul.unordered-smallList {
  margin: 0;
  list-style-type: square;
  font-size: 1.375rem;
  padding-left: 3rem;
  li {
    padding: 0 0 1.5rem;
  }
}
ul.unordered-list-mobile {
  &.main {
    li {
      > * {
        @media (max-width: $max-sm) {
          font-size: 1.6rem;
        }
      }
    }
  }
}
ul.listIcon {
  padding-left: 0;
  list-style: none;
  li {
    padding-left: 35px;
    background-image: url('modules/admissions/components/icons/check_active16x24.svg');
    background-repeat: no-repeat;
    background-position: left top;
  }
}
ol.ordered-list {
  margin: 0;
  padding-left: 3rem;
  li {
    padding: 0 0 1.5rem;
  }
  // default - basic list
  &.main {
    padding-left: 1.9rem;
    margin-bottom: 1.5rem;
    li {
      padding: 0 0 0.6rem;
      > * {
        font-size: 1.6rem;
        line-height: 1.5;
        @media (max-width: $max-sm) {
          font-size: 1.4rem;
          display: block;
        }
      }
    }
  }
  &.tight li {
    padding: 0;
  }
  &.nested-list {
    .ordered-list.main {
      margin-top: 1rem;
      margin-bottom: 0;
      padding-left: 1.5rem;
    }
  }
}
// Genesys Chat
aside.supportTools {
  top: auto;
  bottom: 1rem;
  left: auto;
  right: 4vw;
  margin: 0;
  position: fixed;
  z-index: 10;
  & button.MuiButtonBase-root {
    position: unset;
    margin-bottom: 5px;
  }
  svg.MuiSvgIcon-root {
    font-size: 4.65rem;
    &.openButton {
      font-size: 3.25rem;
    }
    &.closeButton {
      font-size: 2.75rem;
    }
  }
  .chatIcon {
    fill: white;
  }
  .textIcon {
    fill: white;
  }
  @media (min-width: $min-md) {
    bottom: 3vh;
  }

  max-width: 6rem;
  padding: 0.4rem;
  // background-color: #bd2e1f;
  // border: 0.3rem solid #bd2e1f;
  border-radius: 0.8rem;
}
body {  
  .cx-webchat,
  .cx-callback {
    &.cx-widget.cx-theme-light {
      // Chat Icons
      .cx-svg-icon-tone1 {
        fill: $color-phoenix-red;
      }
    }
    .cx-button-group {
      padding: 8px;
    }
    .cx-btn {
      margin-bottom: 0.5rem;
      border-radius: 2.8rem !important;
      &.cx-btn-default {
        color: #3c2320;
      }
    }
    .cx-footer {
      display: none !important;
    }
  }
  .cx-webchat .cx-form{
    padding: 24px;
    padding-top: 0;
  }
  .cx-callback .cx-content {
    padding-top: 0;
  }
}
div.cx-form-inputs > table {
  border-width: 0;
}

// Genesys Cobrowse
body {
  .gcb-toolbar {
    background-color: #9f2619;

    .gcb-toolbar-top {
      border-bottom: 1px solid #8d2216;
    }
    // Exit Button
    .gcb-toolbar-exit svg path {
      fill: #d0d0d0;
    }
  }
  // Toast Notification
  #_gcb_toast-container {
    ._gcb_toast {
      background-color: rgba(159, 38, 25, 0.94);
      border-top: 1px solid #9f2619;
    }
  }
  // Tooltips
  .gcb-tooltip.gcb-top {
    .gcb-tooltip-arrow {
      border-top-color: #9f2619;
    }
    .gcb-tooltip-inner {
      background-color: #9f2619;
    }
  }
  // Status
  .gcb-appStatus {
    background-color: #9f2619;
  }
}
// Utility
.hide {
  display: none;
}
.line-break {
  border-top-color: $separation-color;
  border-top-width: 0.3rem;
  border-top-style: solid;
  margin: 5rem 0 5rem 0;
  @media (min-width: $min-lg) {
    margin-right: 3rem;
  }
  &.lastLine {
    margin: 1.6rem 0 0 0;
    @media (min-width: $min-lg) {
      margin: 3rem 0 0 0;
    }
  }
}
.sideSeparator {
  border-bottom-color: $gridVerticalSectionSeparator;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  padding-bottom: 3.5rem;
  @media (min-width: $min-md) {
    border-right-color: $gridVerticalSectionSeparator;
    border-right-width: 0.1rem;
    border-right-style: solid;
    padding-right: 3.5rem;
    border-bottom: 0;
    padding-bottom: 0;
  }
}
// ellipsis - add title to link with value
.ellipsisLink {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ADA
// ------------------------------
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
// Skip to content - first focus doesn't have attr modality
.skip-to-content {
  color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  &:focus {
    margin: 1px;
    padding: 6px 12px;
    color: $focusTextColor;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    border: 2px #000 solid;
    background-color: #fff;
    z-index: 1101;
  }
}
.skip-list {
  list-style-type: none;
}
// Keyboard modality
body[modality=keyboard] {
  #root {
    button.MuiButtonBase-root[id="next_step"]:focus,
    button.MuiButton-root.mainBtn:focus,
    button.MuiButtonBase-root.MuiIconButton-root:focus,
    a.MuiButton-root.mainBtn:focus,
    button.MuiButtonBase-root.MuiFab-root:focus,
    a.MuiButtonBase-root[id="next_step"]:focus,
    .taskActions a.MuiButtonBase-root:focus,
    a.linkForButton:focus {
      border-radius: 3rem;
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
    a:focus, 
    h1:focus, 
    main:focus,
    .MuiExpansionPanel-root .MuiButtonBase-root:focus,
    .tooltip-icon [tabindex="0"]:focus,
    button.MuiButtonBase-root:focus,
    a.MuiButtonBase-root:focus,
    a.MuiButtonBase-root :focus,
    .MuiFormControl-root[tabindex="0"]:focus,
    svg.disabled-padlock-icon:focus,
    .videoPlayBtn:focus,
    [role="link"].textLink:focus,
    [role="checkbox"][tabindex="0"]:focus,
    div.g-recaptcha:focus,
    button#transition-chat:focus,
    .linkWithIcon button:focus {
      border-radius: 0;
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
    a[id^="footnoteRef"]:focus {
      border-radius: 0;
      outline: none;
    }
    a.dynamicMenu:focus {
      border-radius: 0;
      outline-color: $focusOnBlackBkgdColor;
      @include focusOutline(2px);
    }
    [aria-labelledby=tableProgramSelection] {
      &:focus {
        outline-color: $footerFocusOutlineColor;
        @include focusOutline(2px);
      }
    }
    button.explandAll:focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(-2px);
    }
    .MuiToggleButtonGroup-root button.MuiToggleButton-root {
      &:focus {
        outline: none;
        border-radius: 3rem;
      }
    }
    .MuiTable-root {
      .MuiTableSortLabel-root {
        &:focus {
          outline-color: $footerFocusOutlineColor;
          @include focusOutline(2px);
        }
      }
    }
    > div > footer .MuiContainer-root a,
    > footer .MuiContainer-root a {
      &:focus {
        outline-color: $footerFocusOutlineColor;
        @include focusOutline(2px);
      }
    }
    .logoutLink:focus,
    .questionCall:focus {
      outline-color: $color-white;
      @include focusOutline(-1px);
    }
    .videoPlayThemeBtn:focus {
      border-radius: 4rem;
      outline-color: $color-white;
      @include focusOutline(2px);
    }
  }
  // Modal
  a.MuiButtonBase-root:focus,
  button.MuiButtonBase-root:focus,
  .MuiTypography-root a.textLink:focus,
  a#identity-verify-phone-link:focus {
    outline-color: $mainFocusOutlineColor;
    @include focusOutline(2px);
  }
  button.videoCloseBtn:focus,
  #transcriptContent:focus {
    outline-color: $focusOnBlackBkgdColor;
    @include focusOutline(4px);
  }
  a.linkForButton:focus {
    border-radius: 3rem;
    outline-color: $mainFocusOutlineColor;
    @include focusOutline(2px);
  }
  // Not supported browser
  #ie11NotSupported a {
    &:focus,
    :focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(0);
    }
  }
  // Simple header logo
  a#quickAppLogo:focus {
    outline-color: $mainFocusOutlineColor;
    @include focusOutline(2px);
  }
  // Webchat
  .cx-webchat {
    &:focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(4px);
    }
    div:focus,
    button:focus,
    li:focus,
    .cx-option:focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
    textarea:focus {
      outline-color: $mainFocusOutlineColor; // , 20%
      @include focusOutline(4px);
    }
  }
  // Cobrowse
  .gcb-toolbar {
    &:focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
    .gcb-toolbar-exit:focus {
      outline-color: darken($mainFocusOutlineColor, 20%);
      @include focusOutline(0);
    }
  }
  // Text
  .cx-callback {
    &:focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(4px);
    }
    div:focus,
    button:focus,
    li:focus,
    .cx-option:focus {
      outline-color: $mainFocusOutlineColor;
      @include focusOutline(2px);
    }
  }
  // custom radio
  .radio-group-fieldset input:focus + div > svg {
    border-radius: 3rem;
    outline-color: $mainFocusOutlineColor;
    @include focusOutline(4px);
  }
}
// Set focus to logout link
.UopRefLinkLogout {
  list-style-type: none;
  padding-left: 2rem;
  a:focus {
    outline-color: $mainFocusOutlineColor;
    @include focusOutline(2px);
  }
}
// New keyboard modality - focus-visible lib
.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}
.focus-visible:focus {
  border-radius: 0;
  outline-color: $mainFocusOutlineColor;
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
}
// h1
h1.h1.focus-visible:focus {
  border-radius: 0;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(2px);
}
// Controls
// Textfield
.MuiFormControl-root.focus-visible:focus {
  border-radius: 0;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(2px);
}
// button
button#loginButton[data-focus-visible-added]:focus,
button#accountCreation[data-focus-visible-added]:focus,
button.MuiIconButton-root[data-focus-visible-added]:focus,
button#ppacContinue[data-focus-visible-added]:focus {
  border-radius: 3rem;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(2px);
}
// links
#page-back-btn[data-focus-visible-added]:focus,
a.textLink.focus-visible:focus,
button.textLink.focus-visible:focus,
a.skip-to-content.focus-visible:focus {
  border-radius: 0;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(2px);
}
a#recoverLink[data-focus-visible-added]:focus {
  border-radius: 0;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(-2px);
}
// radio button
input#isUsCitizen-yes[data-focus-visible-added]:focus,
input#isUsCitizen-no[data-focus-visible-added]:focus {
  border-radius: 3rem;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(2px);
}
.focus-visible:focus + span + span.MuiTouchRipple-root {
  border-radius: 3rem;
  outline-color: $mainFocusOutlineColor;
  @include focusOutline(-4px);
}

// PAGE SPECIFIC
// --------------------------------------------------------
.page-wrapper {
  min-height: 24rem;
  @media (min-width: $min-md) {
    min-height: 0;
  }
}

// DASHBOARD
#pgDashboard {
  background-color: transparent;
  // identity verify failed message
  .warning-msg-noicon {
    .MuiGrid-root.MuiGrid-item {
      > div:not(.identityVerifyFailed) {
        display: none;
      }
    }
  }
}
// Navigation
a.navLink {
  color: $linkColor;
  text-decoration: none;
  &:hover {
    color: $linkColor;
    text-decoration: underline;
  }
  &.MuiButton-text {
    color: $linkColor;
    &:hover {
      color: $linkColor;
    }
  }
}
// Letter center dialog
#decisionLetterDialog .MuiDialog-paperWidthMd {
  @media (min-width: $min-lg) {
    max-width: 1280px !important;
  }
}
// navigation drop down - Make link clickable over its full width
[id*="secondaryMenu"] .MuiMenu-paper .MuiList-root .MuiMenuItem-root {
  width: 100%;
  a {
    width: 100%;
  }
}
// First page - personal info - Test
body[data-first="personalInfo"] {
  .bottomHeader {
    a > svg {
      height: 4.8rem;
    }
    @media (min-width: $min-lg) {
      a > svg {
        height: 4.6rem;
      }
    }
  }
}
.formPersonalInfo {
  .legalNameHeading {
    margin-top: 1.5rem;
  }
  .form-note {
    font-style: normal;
    color: $textColor;
  }
}

#root .ackBtn {
  margin-bottom: 3rem;
}
.MuiButton-root.printBtn {
  color: $color-white;
}
.MuiDialog-container .acknowledgeDocs table {
  border: none;
}
// contact info
#pgContactInfo {
  .legalMainNameHeading {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 2.4rem;
    @media (min-width: $min-md) {
      font-size: 2.4rem;
    }
  }
}
// More
.tooltipAfterText.ssnTooltip {
  margin-bottom: 1.2rem;
  .MuiTooltip-popper > .MuiTooltip-tooltip {
    ul.list {
      margin: 1rem 0!important;
      padding-left: 2.5rem;
    }
  }
}
// military
.page-wrapper .MuiAutocomplete-root[name="veteranOrgForDOD"] .MuiInputBase-root.MuiOutlinedInput-adornedEnd {
  padding-right: 39px;
}
// errorConfirmButtonGroup
p.errorConfirmButtonGroup {
  margin-top: -3rem;
  margin-bottom: 3rem;
}

// Autocomplete override <-- Need to refactor
.autoCompleteIconOverride {
  margin-right: -30px;
}
// Zipcode warning
.zipWarningText {
  padding-bottom: 12px;
}
// Acknowledgements
.doc-wrapper {
  overflow-x: hidden;
}
// animation slide-in-left
.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
// animation slide-out-right
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.choosePathIcon {
  opacity: 1;
  mix-blend-mode: normal;
  overflow: visible;
  transform: rotate(0deg);
}
// Secondary page
[data-shrink=true] label {
  transform: translate(14px, 4px) scale(0.75);
}
// CYOP page
#showLivingExpenses-radio-group {
  margin-top: 3rem;
  .desc-radio-group {
    color: $textHelpHintColor;
    font-weight: 400;
    margin-top: -1.4rem;
  }
}
// Financial
// SelectionQuestion component - globalStyles
[data-child="true"] {
  fieldset.radio-group-fieldset {
    margin-top: 1.6rem;
  }
}
// work page
.paper {
  h4 {
    margin-bottom: 1rem;
  }
  ul.unordered-list {
    font-size: 1.6rem;
    padding-left: 2rem;
    li::marker {
      font-size: 2.2rem;
    }
  }
}
.showWorkRules {
  background-color: lightgrey;
  margin: 0 -2rem 5rem;
  padding: 1rem 2rem;
}
ul.generalList {
  margin-bottom: 4rem;
}
#pgWork {
  button.MuiButton-text.addRemove + hr.MuiDivider-root {
    margin-bottom: 2rem;
  }
  form + button.MuiButton-text.addRemove {
    margin-top: 0;
  }
}
// Review page
#pgReview {
  .reviewPanel {
    .MuiExpansionPanelSummary-content,
    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0;
      h2, h3, h4, h5, h6 {
        font-size: 1.4rem;
      }
    }
  }
}
// Review Panel
.reviewPanel {
  margin-top: 1.2rem !important;
  width: 100%;
  @media (max-width: $max-sm) {
    font-size: 1.6rem;
  }
  &.error {
    border-color: $color-phoenix-red-d20;
    border-width: 2px;
  }
  .reviewSectionError {
    background-color: $color-phoenix-red-d20;
  }
  .reviewSectionHeading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    margin-top: 2.4rem;
    .reviewSectionTitle {
      color: #5e7079;
      font-size: 1.6rem;
      @media (max-width: $max-sm) {
        font-size: 1.8rem;
      }
      font-weight: 500;
    }
  }
  .MuiExpansionPanelDetails-root { 
    padding: 1.6rem 1.2rem 1.6rem;
  }
  .reviewSectionDivider {
    margin-top: 2.4rem;
  }
  .reviewSectionFieldDivider {
    margin: 1.4rem auto;
    width: 70%;
    visibility: hidden;
  }
  .reviewSectionField {
    display: flex;
    @media (max-width: $max-sm) {
      display: block;
    }
    p {
      font-size: 1.6rem;
      padding: 0.5rem 0;
      justify-content: space-between;
      @media (max-width: $max-sm) {
        text-align: left;
      }
    }
    .reviewSectionFieldLabel {
      width: 26%;
      min-width: 26%;
      @media (max-width: $max-sm) {
        font-weight: 500;
        width: auto;
        padding-bottom: 0;
      }
      &.error {
        color: $color-phoenix-red-d20;
      }
    }
    .reviewSectionFieldValue {
      display: block;
      @media (max-width: $max-sm) {
        padding-top: 0;
      }
      &.error {
        color: $color-phoenix-red-d20;
      }
    }
    .reviewSectionFieldErrorIcon {
      cursor: pointer;
      margin-left: 0.5rem;
      margin-top: -3px;
      vertical-align: middle;
      font-size: 1.6rem;
    }
  }
  .reviewSectionTitle {
    .reviewSectionFieldLabel, .reviewSectionFieldValue {
      font-weight: 500;
    }
  }
  .reviewSectionFieldError {
    color: $color-phoenix-red-d20;
    margin-bottom: 1.5rem;
    p {
      @media (max-width: $max-sm) {
        font-size: 1.4rem;
      }
    }
  }
  .ErrorIcon {
    font-size: 1.6rem;
    color: $color-phoenix-red-d20;
  }
  // hidden secondary divider
  .noSecondaryInfo + .MuiGrid-root.MuiGrid-container {
    display: none;
  }
}

// Footnotes
// program
#pgProgram {
  .needHelp {
    ul {
      li .phoneHelp {
        margin: 0 2rem 0.8rem 0;
      }
    }
  }
  .footnotesWrapper {
    font-size: 1.4rem;
    margin-left: -0.3rem;
  }
}

// Page: pgUpload
#pgUpload {
  nav {
    display: flex;
    .center {
      margin: auto;
      font-size: 1.6rem;
    }
    .bottomReturnBtn  {
      margin-top: 2rem;
      text-align: center;
      flex: auto;
    }
  }
}

// Custom Alert
.DialogContent {
  margin-top: -3rem;
  .hidePlease, .hideLink, .hideFinalDot, .hidePartialMessage {
    display: none;
  }
  #sessionCtaAlert {
    margin: 0;
    padding: 0.2rem 0 0;
  }
  min-height: '100px';
}

// Maintenance
#maintenancePg {
  @media (max-width: $max-sm) {
    padding-left: 0;
    padding-right: 0;
    main > div:first-child {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    main ul li:last-child {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  @media (max-width: $max-md) {
    main ul li:last-child {
      margin-top: 2rem;
    }
  }
}
// Acknowledge & Sign and submit print
#document-page {
  #docToDisplay {
    .printBtnWrapper {
      height: 4rem;
    }
  }
}
// My signed application
#mySubmittedApplication {
  .MuiDialog-paperWidthMd {
    @media (min-width: $min-lg) {
      max-width: 88rem!important;
    }
  }
  #docToDisplay {
    table[role="presentation"],
    table.awf-form,
    table[id^="Table"],
    table.Tag_Visibility,
    table.enrollmentTitle,
    table.hardSoftPage,
    #nursing-license .awf-form table {
      border: 0;
    }
    awforms table.awf-form {
      margin: 0 auto;
    }
  }
}
// Welcome & sign and submit
.reEnrollIpg2, .reEnrollIpg1 {
  display: none;
}
.welcome-page .reEnrollIpg1 {
  display: block;
}
.reviewAndSign-page .reEnrollIpg2 {
  display: block;
}
// sign and submit pdf modal
@media (min-width: $min-md) {
  body[data-modal=pdf] .MuiDialog-root {
    .MuiDialog-paper {
      height: 100%;
      min-height: 50%;
    }
    #document-page {
      padding-left: 0!important; // important needed to overide library inline style
      padding-right: 0!important; // important needed to overide library inline style
    }
  }
}
// base64 pdf
#document-page.pdf .react-pdf__Document {
  canvas.react-pdf__Page__canvas, .react-pdf__Page__textContent {
    @media (max-width: $max-sm) {
      width: 110%!important; // important needed to overide library inline style
    }
    width: 100%!important; // important needed to overide library inline style
  }
  .react-pdf__Page__textContent {
    @media (max-width: $max-sm) {
      display: none;
    }
    > span {
      width: auto;
    }
  }
}

// TODO: Form control override - Refactor when formik is no longer part of the project
body {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
    .MuiAutocomplete-input {
      padding: 24px 0 18px 0;
      &:first-child {
        padding-left: 14px;
      }
    }
  }
  .MuiFormControl-root.MuiTextField-root {
    padding-top: 0;
  }
  .MuiFormControl-root .MuiInputBase-root {
    select, input {
      margin-right: -22px;
      padding-right: 36px;
    }
    input#email[aria-invalid="true"] {
      margin-right: -45px;
    }
    #emailIntro input#email[aria-invalid="true"] {
      margin-right: -22px;
    }
  }
  #pgProgram .MuiFormControl-root .MuiInputBase-root {
    select, input {
      margin-right: -22px;
      padding-right: 12px;
    }
  }
  .MuiAutocomplete-root {
    .MuiFormControl-root .MuiInputBase-root {
      select, input {
        margin-right: -22px;
        padding-right: 36px;
      }
    }
    &[name="addressLine"] .MuiInputBase-root {
      padding: 0 21px 0 0;
      .MuiInputAdornment-root {
        position: absolute;
        right: 36px;
      }
    }
    &[name="addressLine"] .MuiInputBase-root input {
      padding: 24.5px 36px 12.5px 15px!important;
    }
  }
}

// Need Help
.needHelp {
  background-color: $backgroundLightBlue;
}

// ONETRUST privacy footer link
#onetrust-consent-sdk {
  #close-pc-btn-handler {
    height: 44px!important;
    width: 44px!important;
    margin: 0 10px 0 0!important;
    background-size: 16px!important;
  }
  #onetrust-pc-sdk {
    a.privacy-notice-link {
      color: #3860BE!important;
    }
  }
}
// Identity Verify
body > .MuiDialog-root#identity-verify > .MuiDialog-container {
  > .MuiPaper-root.MuiDialog-paper {
    max-width: 80rem;
    overflow: hidden;
    @media (max-width: $max-sm) {
      margin-right: 0!important;
      margin-left: 0!important;
      max-height: 100%!important;
      margin: 0!important;
      border-radius: unset!important;
    }
    @media (max-height: 750px) {
      display: block;
      overflow: auto;
    }
  }
  #identityPolicy {
    .printBtnWrapper {
      margin-top: 2rem;
      height: 5rem;
      text-align: right;
    }
  }
}
// Identity verify - regBuild
#pgIdentityVerify {}
.InfoAndIllustration {
  .IdVerifyDataInfo {
    margin-top: 1rem;
    margin-bottom: 1.6rem;
  }
}
.howIdentityVerifyWorks + .footnotesWrapper {
  margin-top: 1.6rem;
  ol li {
    font-size: 1.6rem;
  }
}
a.idVerifyPhoneNumber, a.idVerifyEmail {
  padding: 0;
  vertical-align: inherit;
}
.verifyIdentityCtaInfoContainer {
  text-align: center;
  .verifyIdentityCtaInfo {
    margin: 2rem auto 2rem;
    max-width: 102rem;
    color: #617078;
  }
}
.howIdentityVerifyWorks {
  .verifyIdentityTip {
    margin: 1rem auto 0;
    max-width: 92rem;
    text-align: start;
    border-color: $color-almost-black-l60;
    .ctaHeaderGrid.lightbulbHeaderGrid {
      svg path {
        fill: $color-white;
      }
      > :first-child {
        padding: 0;
      }
    }
    .MuiGrid-root:last-child {
      padding: 1.5rem 0.5rem 1.5rem 0.5rem;
    }
    h4.MuiTypography-root {
      font-size: 2rem!important;
      color: $alert-header-on-bkgd-color;
    }
  }
}

// PROGRESSIVE IMAGE
a.progressive {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
}

a.progressive:not(.replace) {
  cursor: pointer!important;
}

a.progressive img {
  display: block;
  width: 100%;
  height: auto;
  border: 0 none;
  @media (max-width: $max-sm) {
    width: 160px;
  }
}

a.progressive img.preview {
  filter: blur(2vw);
  transform: scale(1.05);
}

a.progressive img.reveal {
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform, opacity;
  animation: reveal 1s ease-out;
}

img.blackTheme {
  width: 100%;
}

@keyframes reveal {
  0% { transform: scale(1.05); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

// KEYFRAMES
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

// RESPONSIVE
// --------------------------------------------------------
@media (max-width: $max-sm) {
  body {
    // Picker Popover for a list (dropped into body root)
    > .MuiPopover-root .MuiButtonBase-root {
      white-space: normal;
      padding-left: 2rem;
    }
    // Dialog (dropped in the body root)
    > .MuiDialog-root .MuiDialogTitle-root {
      text-align: left;
    }
  }
}

// DOCUMENTATION
#pgDoc {
  #skipto {
    display: none;
  }
}

// PRINT
// -----------------------------
@media print {
  body {
    background-color: $color-white;
  }
}
#docToDisplay .printBtn {
  margin-left: 0.5rem;
}

body {
  @extend %baseline;
  @import 'theme';
}

// THEME
// ====================
html {
  background-color: unset;
  &:not([data-html="page-rendered"]) {
    #root {
      header.mainHeader, footer[role="contentinfo"], aside.supportTools {
        display: none;
      }
    }
  }
  // placeholder color
  .MuiInputBase-input::-webkit-input-placeholder {
    color: black;
    opacity: 0.54;
  }
  .MuiInputBase-input::-moz-placeholder {
    color: black;
    opacity: 0.54;
  }
  .MuiInputBase-input:-ms-input-placeholder {
    color: black;
    opacity: 0.54;
  }
  .MuiInputBase-input::-ms-input-placeholder {
    color: black;
    opacity: 0.54;
  }
}
html[data-css="dashboard"] {
  background-color: $color-blue;
}
html[data-pg="pgWrapper"], html[data-pg="pgStandAloneWrapper"] {
  background-image: url('./components/icons/phoenix/uopxLogoLightBlue2.svg');
  background-repeat: no-repeat;
  background-position-x: 112%;
  background-position-y: 8rem;
  background-size: 200px auto;
  @media (min-width: $min-md) {
    background-position-x: 85%;
    background-position-y: 1rem;
    background-size: 400px auto;
  }
}
// Dashboard
body[data-theme="dashboardTheme"] {
  &[modality=keyboard] {
    #root {
      // Simple header logo
      a#quickAppLogo:focus {
        outline-color: $mainFocusOutlineColor;
        border-radius: 3rem;
      }
      // topHeader links
      .logoutLink:focus,
      .questionCall:focus {
        outline-color: $footerFocusOutlineColor;
        border-radius: $header-top-links-radius;
        @include focusOutline(2px);
      }
      footer > .MuiContainer-root a {
        &:focus {
          outline-color: $color-white;
        }
      }
    }
  }
}
// Verify your identity post-sign (from Dashboard)
#pgVerifyYourId {
  .bottomReturnBtn  {
    margin-top: 2rem;
    text-align: center;
  }
  .verifyIdentityWarning {
    margin-top: 2rem;
    margin-bottom: 2rem!important;
  }
}
#pgVerifyYourId, #pgVerifyYourIdReview, #pgIdentityVerify {
  // Identity verify
  .InfoAndIllustration {
    h2.MuiTypography-root {
      font-size: 2.4rem!important;
    }
    #videoChatCard {
      .MuiTypography-h2, .MuiTypography-h3 {
        font-size: 2rem!important;
      }
      & .videoChatHours {
        padding: 1.4rem 0 0;
        border-top: 1px solid $card-muted-border-color;
        margin: 1.4rem auto 2rem auto;
        width: 25rem;
        @media (min-width: $min-md) {
          width: auto;
        }
        .timecalendarHeaderGrid {
          height: 3.4rem;
          .MuiGrid-item:last-child .MuiTypography-root {
            font-size: 1.4rem!important;
          }
        }
        ul.unordered-list.main {
          padding-left: 0;
          list-style-type: none;
          text-align: left;
          li {
            padding: 0 0 0.6rem;
            line-height: 0.8;
            span {
              line-height: 1;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  // FAQs
  .idVerifyIDList {
    padding-left: 1.6rem;
    li {
      font-size: 1.6rem;
    }
  }
  @media (max-width: $max-sm) {
    .VerifyIdentitySectionTwo .verifyIdentityTipContainer .verifyIdentityTip {
      border: 0;
      padding: 0;
      margin: 2rem auto 0;
      ul.unordered-list.main li > *, ol.ordered-list.main li > * {
        font-size: 1.6rem;
      }
    }
  }
  // footnote
  .footnotesWrapper {
    font-size: 1.6rem;
  }
}
// Action Items
#pgActionItems {
  .page-wrapper {
    padding-top: 0;
  }
  .pgTitle {
    display: none;
  }
  .taskPageSection {
    padding: 0;
    margin-bottom: 0;
    @media (min-width: $min-md) {
      padding-top: 1.6rem;
    }
    .taskSection {
      margin-bottom: 2rem;
    }
  }
  .taskSection {
    margin: 0 auto;
    @media (min-width: $min-md) {
      max-width: 99.6rem;
    }
    h2.leadHdg {
      font-size: 2.4rem;
      @media (min-width: $min-md) {
        font-size: 3.2rem;
      }
    }
    .taskUnorderedList > li.taskListItem {
      @media (min-width: $min-md) {
        margin-left: 9.4rem;
        max-width: 80rem;
      }
    }
  }
  .bottomReturnBtn  {
    margin-top: 2rem;
    text-align: center;
  }
  // tasks
  .MuiAccordionDetails-root {
    font-size: 1.6rem;
  }
}
// student life
html[data-sub-pg="studentLife"] {
  background-image: none;
  .headerAttachment {
    display: none;
  }
  .pgTitle {
    display: none;
  }
  // Hiding default pageWrapper back btn/h1
  .pageTopNav {
    display: none;
  }
  #pgStudentLife {
    padding: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    .pgWrapperGrid {
      @media (min-width: $min-lg) {
        padding: 0 0 4rem;
      }
    }
    > div > div {
      @media (min-width: $min-md) {
        padding: 2rem 0rem 4rem;

      }
    }
    // Restructuring the wereHereToHelp component
    // by hiding some columns
    .wereHereToHelp {
      > .MuiGrid-container {
        flex-direction: column;
        > .MuiGrid-item:first-child {
          flex-grow: 0;
          max-width: 100%;
          flex-basis: 100%;
          > h2 {
            display: none;
          }
          > .MuiGrid-container {
            width: 100%;
            margin: 0;
            justify-content: stretch;
            .erSupport {
              flex-grow: 0;
              max-width: 100%;
              flex-basis: 100%;
              padding-top: 0;
              padding-right: 0;
              padding-left: 0;
            }
            .techSupport {
              display: none;
            }
          }
        }
        > .MuiGrid-item:last-child {
          display: none;
        }
      }
    }
  }
  .mainContainer {
    margin-top: 4rem;
    margin-bottom: 5rem;
    padding: 1rem 1rem 0;
  }
  // Displaying (not pageWrapper) page back btn/h1
  .backToDashWrap {
    position: absolute;
    z-index: 1;
    top: 6rem;
    margin-bottom: 0;
    margin-right: 30%;
    #accentDashboardApplication span {
      line-height: 1;
    }
    @media (min-width: $min-md) {
      margin-left: 3rem;
    }
    @media (min-width: 820px) {
      top: 12rem;
    }
    @media (min-width: $min-lg) {
      top: 16rem;
      margin-left: 1rem;
    }
  }
  .page-wrapper {
    margin-top: -2rem;
    padding-top: 0;
    @media (min-width: $min-lg) {
      margin-top: 0;
    }
  }
  .menuPageScroll {
    overflow: hidden;
    width: 100%;
    position: relative;
    @media (min-width: $min-lg) {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }
  .menuScroll {
    position: relative;
    z-index: 1;
    @media (min-width: $min-lg) {
      position: sticky;
      z-index: unset;
      top: 8rem;
      flex: none;
      margin-left: 3rem;
      margin-right: 4rem;
    }
  }
  .pageScroll {
    position: relative;
    @media (min-width: $min-lg) {
      margin-top: -2rem;
    }
    > .slSection {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      @media (min-width: $min-md) {
        padding-right: 3rem;
        padding-left: 3rem;
      }
      @media (min-width: $min-lg) {
        padding-right: 0;
        padding-left: 1rem;
      }
    }
    > .line-break {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
      @media (min-width: $min-md) {
        margin-right: 3rem;
        margin-left: 3rem;
      }
      @media (min-width: $min-lg) {
        margin-left: 1rem;
      }
    }
    @media (min-width: $min-lg) {
      flex: auto;
    }
  }
  .slSection {
    @media (min-width: $min-lg) {
      margin-right: 3rem;
    }
    &#financialResources {
      @media screen and (min-width: 992px) and (max-width: 1300px) {
        .slSectionCol1, .slSectionCol2, .slSectionCol3 {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
  ul.slList {
    padding-left: 2rem;
    .listTitle {
      font-weight: 700;
    }
  }
  .slHeadingTwo {
    font-weight: 300;
    font-size: 2.4rem;
    padding-bottom: 1.4rem;
    @media (min-width: $min-lg) {
      font-size: 3.2rem;
    }
  }
  .slHeadingThree {
    font-weight: 700;
    font-size: 2rem;
    padding: 3rem 0 0;
    @media (min-width: $min-lg) {
      font-size: 2.4rem;
    }
  }
  .slIntroPara {
    padding-bottom: 1.4rem;
    font-size: 1.6rem;
  }
  .sl-sideSeparator {
    @media (max-width: $max-sm) {
      padding-right: 0;
    }
  }
  .slSectionOnlineClassroomTwo {
    .slHeadingThree {
      padding: 0;
      margin-top: 3rem;
    }
    .slSectionCol2 {
      .slHeadingThree {
        @media (max-width: $max-sm) {
          margin-top: 1rem;
        }
      }
    }
  }
  .inlineAnchor {
    font-size: 1.6rem;
  }
  #tipOne, #tipTwo, #tipThree {
    h3.MuiTypography-root.MuiTypography-h3 {
      font-size: 1.6rem!important;
    }
  } 
  .slSectionOnlineClassroom {
    .slSectionCol2 {
      width: 100%;
      svg.womanLaptop {
        margin: 0 auto;
        display: block;
        width: 100%;
        @media (min-width: $min-md) {
          margin: 3rem auto 0;
        }
      }
    }
  }
  .slSectionNextSteps {
    .slSectionCol2 {
      width: 100%;
      svg.techSupHeadsetLaptop {
        margin: 0 auto;
        display: block;
        width: 100%;
      }
    }
  }
  aside.sideMenu {
    position: relative;
    width: 100%;
    background-color: transparent;
    height: 100%;
    @media (min-width: $min-lg) {
      position: unset;
    }
  }
  #pageNavigation {
    background-color: $color-dark-river-blue;
    margin: 0;
    padding: 3rem 1.5rem 0.5rem;
    border-radius: 0 0 2rem 2rem;
    position: fixed;
    right: 1rem;
    top: 4.5rem;
    width: calc(100% - 2rem);
    @media (min-width: $min-lg) {
      position: unset;
      top: 3.5rem;
      padding: 3rem 0.2rem 0.5rem 0.2rem;
      width: fit-content;
    }
    ul {
      list-style: none;
      margin: 0;
      overflow: hidden;
      overflow-x: auto;
      padding: 1rem 0;
      text-align: center;
      white-space: nowrap;
      @media (min-width: $min-lg) {
        padding: 0;
        text-align: left;
        overflow-x: unset;
      }
    }
    li {
      display: inline;
      margin-bottom: 0;
      @media (min-width: $min-lg) {
        display: list-item;
        margin: 1rem 0 3rem;
      }
    }
    a.dynamicMenu {
      color: $color-white;
      font-size: 1.4rem;
      margin: 0 1.5rem;
      text-decoration: none;
      position: relative;
      &::before {
        bottom: -0.3rem;
        background-color: $color-white;
        height: 0.3rem;
        content: "";
        width: 0;
        left: 0;
        border-radius: 3rem;
        position: absolute;
        transition: width 0.3s ease-in-out;
      }
      &:hover::before,
      &.active::before {
        width: 100%;
      }
    }
    @media (min-width: $min-lg) {
      a.dynamicMenu {
        font-size: 1.8rem;
        &::before {
          bottom: -0.5rem;
        }
      }
    }
  }
  .bottomReturnBtn {
    margin-top: 2rem;
    text-align: center;
  }
}
// Upload
// Hide analytics img
body > img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// reCAPTCHA
.grecaptcha-badge {
  // bottom: 9.6rem!important;
  visibility: hidden;
}
.grecaptcha-branding {
  margin-top: 2.2rem;
  font-size: 1.2rem;
  color: $color-gray;
  a {
    color: inherit;
  }
}
// reCAPTCHA v2
.g-recaptcha {
  > div {
    margin-top: 2.2rem;
  }
}
.recaptchaError {
  margin-top: 1.2rem;
}
// Identity Verify - regBuild
html#pgIdentityVerify {
  header.mainHeader {
    .bottomHeader {
      .dashboardDesktopHDlogo, .dashboardMobileHDlogo {
        display: none;
      }
    }
    .topHeaderWrapper {
      display: none;
    }
  }
  // heading
  .MuiTypography-h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    @media (min-width: $min-md) {
      font-size: 3.6rem;
    }
  }
  .MuiTypography-h2 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: inherit;
  }
  .MuiTypography-h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: inherit;
  }
}
// Verify identity video chat
body[data-ping="ping-video"],
body[data-ping="video"],
body[data-ping="ping1"],
body[data-ping="ping2"],
body[data-ping="final"] {
  .howIdentityVerifyWorks {
    padding: 0;
    border: 0;
  }
}
body[data-ping="ping-video"] {
  .ctaHeaderGrid {
    padding-top: 2rem;
    @media (min-width: $min-lg) {
      padding-top: 4rem;
    }
  }
}
body[data-ping="video"] {
  .ctaHeaderGrid {
    padding-top: 2rem;
    @media (min-width: $min-lg) {
      padding-top: 4rem;
    }
  }
  .videoContent {
    flex-direction: column;
  }
}
body[data-ping="final"] {
  .videoIdVerifyContainer {
    @media (min-width: $min-md) {
      padding-top: 4rem!important;
    }
  }
}